$screening-color: #C4C4C4;
$qualified-color: #717171;
$interviews-color: #4F6EDA;
$offer-color: #71D5EB;
$hired-color: #56BB6C;
$rejected-color: #DB3832;
$offer_declined-color: #E9C509;

.stage-label-screening:hover{
  color: $screening-color !important;
}

.stage-label-qualified:hover{
  color: $qualified-color !important;
}

.stage-label-interviews:hover{
  color: $interviews-color !important;
}

.stage-label-offer:hover{
  color: $offer-color !important;
}

.stage-label-hired:hover{
  color: $hired-color !important;
}

.stage-label-rejected:hover{
  color: $rejected-color !important;
}

.stage-label-offer_declined:hover{
  color: $offer_declined-color !important;
}